import { useCallback, useState } from "react";
import { Input } from '../components/ui/input';
import { useZenbeliBackend } from '../hooks/useZenbeliBackend'
import { useParams } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import greenButton from "../components/ui/green-button-classes";

const PasswordReset = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const db = useZenbeliBackend()
  let { token } = useParams();

  const submitLogin = useCallback(() => {
    if (password !== confirmPassword) {
        toast.error("Passwords do not match")
        return;
    }
    if(!!!token) {
        toast.error("No token provided, incorrect URL")
        return;
    }

    db.endpoints.api.passwordResetCreate(token, {password, confirm_password: confirmPassword}).then(() => {
        window.location.href = "/"
    }).catch((error) => {
        toast.error(error.response.data.message)
    });

  }, [password, confirmPassword, token]);

  return (
    <div className="flex bg-secondary flex-col w-full md:w-1/2 lg:w-1/3 self-center gap-6 p-6 drop-shadow-2xl text-center">
      <Toaster />
      <h1 className="text-glow glow-shadow text-xl">Reset Password</h1>
      <Input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
      <Input type="password" placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} />
      <button className={greenButton} onClick={submitLogin}>Reset Password</button>
    </div>
  )
}

export default PasswordReset