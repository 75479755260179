import { useEffect, useState } from "react";


import FormattingContainer from "../components/ui/formatting-container";
import Markdown from 'react-markdown'
import { useZenbeliBackend } from "../hooks/useZenbeliBackend";
import { Article } from "../api/ZenbeliBackendApi";
import ZenLoading from "./ZenLoading";


const KnowledgeCenter = () => {
    const db = useZenbeliBackend();
    const [articles, setArticles] = useState<Article[]>([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if(articles.length > 0) {
            setLoading(false)
            return
        }
        const fetchArticles = async () => {
            const response = await db.endpoints.api.articlesList()
            setLoading(false)
            setArticles(response.data)
        }
        fetchArticles()
    }, []);
    console.log(articles)
    
    const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);

    if (selectedArticle) {
        return (
            <div className="flex flex-col gap-4">
                <button 
                    onClick={() => setSelectedArticle(null)}
                    className="w-fit px-4 py-2 bg-zenbeliDarkBlue hover:bg-[#1a3d40] rounded-md"
                >
                    ← Back to Articles
                </button>
                <h1 className="text-2xl font-bold">{selectedArticle.title}</h1>
                <FormattingContainer>
                    <Markdown>{selectedArticle.content}</Markdown>
                </FormattingContainer>
            </div>
        );
    }

    return (
        <div className="flex flex-col gap-4">
            <h1 className="text-2xl font-bold">Knowledge Center</h1>
            {loading && <ZenLoading />}
            <div className="grid gap-4">
                {articles.map((article) => (
                    <button
                        key={article.title}
                        onClick={() => setSelectedArticle(article)}
                        className="text-left p-4 bg-zenbeliDarkBlue hover:bg-[#1a3d40] rounded-md"
                    >
                        <h2 className="text-xl font-semibold">{article.title}</h2>
                    </button>
                ))}
            </div>
        </div>
    );
}

export default KnowledgeCenter;