import Modal from 'react-modal';
import { useZenbeliBackend } from "../hooks/useZenbeliBackend";
import { useState, useEffect } from 'react';
import ZenLoading from './ZenLoading';
import { IconX } from '@tabler/icons-react';
import styled from '@emotion/styled';


const UlContainer = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 30px;

    li {
        color: inherit;
        position: relative;
        padding-left: 20px;
        margin-bottom: 10px;
        list-style-type: none;
        
        strong {
            color: inherit;
        }
        
        &::before {
            content: "✓";
            color: #C84107;
            font-size: 20px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
`


export const SubscriptionModal = ({ isOpen, setIsOpen }: { isOpen: boolean, setIsOpen: (v: boolean) => void }) => {
    const db = useZenbeliBackend()


    const [checkoutURL, setCheckoutURL] = useState<string | null>(null)

    const getCheckoutURL = async () => {
        const response = await db.endpoints.api.stripeCheckoutCreate({
            slug: 'smart-dashboard'
        })
        console.log("Response",response)
        if (response.data) {
            setCheckoutURL(response.data.stripe_checkout_url)
        }
    }

    useEffect(() => {
        getCheckoutURL()
    }, [])

    return   <Modal
    isOpen={isOpen}
    onRequestClose={() => (setIsOpen(false))}
    style={{
      overlay: {
        background: 'transparent',
        zIndex: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      content: {
        border: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'fit-content',
        height: 'auto',
        inset: 'auto',
        background: 'transparent',
        // Mobile media query
        // @ts-ignore
        '@media (max-width: 768px)': {
          padding: '1rem',
        },
        zIndex: 1000
      }
    }}
  >
    <div className="bg-zenbeliLightBlue rounded-lg drop-shadow-lg">
        {
            !!!checkoutURL ? 
            <div className="flex justify-center items-center p-4"><ZenLoading /></div> :
            <div className="flex flex-col gap-4 max-h-[80vh] overflow-y-auto px-4 py-4 mt-4 relative">
                <IconX className="w-4 h-4 absolute top-4 right-6" onClick={() => setIsOpen(false)} />
                <div className="flex flex-col gap-4 pt-8">
                    <h3 className="text-xl font-bold text-center">Personalize Your Wellness Experience</h3>
                    <h4 className="text-lg font-semibold">Zenbeli Wellness Features:</h4>
                    <UlContainer>
                        <div>
                            <li className="text-lg font-semibold">Reports And Assessments</li>
                            <div className="flex flex-col gap-2 pl-7">
                                <div><strong>Downloadable Non-Medical Reports:</strong> Shareable insights for healthcare and wellness providers</div>
                                <div><strong>Deeper Assessments:</strong> In-depth evaluations tailored to your specific needs.</div>
                                <div><strong>Automatic Enrollment to New Assessments:</strong> Stay updated with the latest assessment roll-outs.</div>
                            </div>
                        </div>
                        <div>
                            <li className="text-lg font-semibold">Personalized Nutrition Support</li>
                            <div className="flex flex-col gap-2 pl-7">
                                <div><strong>Curated Trigger Food Lists:</strong> A personalized list of foods to avoid based on your triggers.</div>
                                <div><strong>Curated Healthy Food Lists and Recipes:</strong> Tailored suggestions for a healthier lifestyle.</div>
                            </div>
                        </div>
                        <div>
                            <li className="text-lg font-semibold">Knowledge And Community</li>
                            <div className="flex flex-col gap-2 pl-7">
                                <div><strong>Knowledge Center:</strong> Access condition-specific content curated by our Licensed Director of Health, Wellness, and Compliance.</div>
                                <div><strong>Zenbeli Communities(Beta)</strong> Automatically join supportive wellness communities.</div>
                            </div>
                        </div>
                    </UlContainer>
                </div>
                <a href={checkoutURL} className="bg-zenbeliGreen text-zenbeliDarkBlue px-4 py-2 rounded-lg drop-shadow-lg w-fit">
                    Subscribe
                </a>
            </div>
        }
    </div>
  </Modal>
}

const SubscribeButton = () => {
    const [isOpen, setIsOpen] = useState(false)
    return <>
        <button className="bg-zenbeliGreen text-white px-4 py-2 rounded-lg drop-shadow-lg w-fit" onClick={() => setIsOpen(true)}>Subscribe</button>
        <SubscriptionModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
}

export default SubscribeButton