import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Landing from './pages/Landing';
import UserProfileBuilder from './pages/UserProfileBuilder';
import { UserProvider, useUser } from './hooks/useUser';
import { ZenbeliBackendProvider } from './hooks/useZenbeliBackend';
import { ReactNode } from 'react';
import UserProfile from './pages/UserProfile';
import ZenbeliDigestiveAssistant from "./pages/ZenbeliDigestiveAssistant"
import Account from "./pages/Account"
import PasswordReset from "./pages/PasswordReset"
const RequireLogin: React.FC<{ children?: ReactNode }> = (props) => {
  const userApi = useUser();
  if (userApi.state === "LoggedOut") return <Navigate to={"/login"} />
  return <ZenbeliBackendProvider children={props.children} />;
}

function App() {
  return (
    <div className="App bg-zenbeliBlue min-h-[100vh]">
    <UserProvider mountingScreen={<></>}>
        <BrowserRouter future={{ v7_startTransition: true }} basename={'/'}>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/password_reset/:token" element={<PasswordReset />} />
            <Route path="/user-profile-builder" element={<UserProfileBuilder />} />
            <Route path="/user-profile" element={<RequireLogin><UserProfile /></RequireLogin>} />
            <Route path="/account" element={<RequireLogin><Account /></RequireLogin>} />
            <Route path="/zenbeli_digestive_assistant" element={<RequireLogin><ZenbeliDigestiveAssistant /></RequireLogin>} />
          </Routes>
        </BrowserRouter>
    </UserProvider>
    </div>
  );
}

export default App;
