import styled from '@emotion/styled';

const FormattingContainer = styled.div`
    p {
        margin: .5rem;
    }
    ul {
        padding-left: 1rem;
        margin: .5rem;
        type: disc;
    }
    ol {
        padding-left: 1rem;
        margin: .5rem;
        list-style-type: number;
    }
    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        font-weight: bold;
    }
`

export default FormattingContainer;