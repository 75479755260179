import { useState } from "react";
import greenButton from "./ui/green-button-classes";
import { useZenbeliBackend } from '../hooks/useZenbeliBackend'
import { Input } from './ui/input';
import Modal from 'react-modal';
import toast, { Toaster } from 'react-hot-toast';

const SendPasswordResetEmailButton = () => {
    const [email, setEmail] = useState("")
    const [isOpen, setIsOpen] = useState(false);
    const db = useZenbeliBackend();

    const sendEmail = async () => {
        await db.endpoints.api.passwordResetEmailCreate({email: email})
        toast.success("Email sent if it exists in our system")
    }

      
      return(<>
      <Toaster />
      <div className="text-glow text-xs cursor-pointer" onClick={() => setIsOpen(true)}>Reset Password</div>
      <Modal
        isOpen={isOpen} 
        onRequestClose={() => (setIsOpen(false))}
        style={{overlay: {zIndex: 100}, content: {
            padding: 0, 
            zIndex: 1000, 
            background: "transparent", 
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}}
      >
        <div className="flex flex-col justify-top flex bg-zenbeliLightBlue rounded-lg w-1/2 h-1/2 self-center  p-6 drop-shadow-2xl text-center">
            <h1 className="text-glow glow-shadow text-xl md:text-4xl pb-0">Reset Password</h1>
            <p className="flex pt-6 flex-col text-text gap-4 md:gap-8 md:text-2xl self-center w-2/3">
                <Input type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
            </p>
            <div className="flex flex-row self-center gap-4 pt-6">
                <button  className={"rounded-md px-4 py-2 flex bg-zenbeliGreen hover:bg-[#7bc700] rounded-md justify-center items-center gap-1 text-zenbeliDarkBlue"} onClick={() => (setIsOpen(false))}>Cancel</button>    
                <button className={greenButton} onClick={sendEmail}>Send Password Reset Email</button>
            </div>
        </div>
    </Modal></>)
}

export default SendPasswordResetEmailButton;